<template>
	<main id="content" class="content_privacy_policy">
		<div class="alignment">

      <h1>Política de Privacidade</h1>

    </div>
  </main>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'PrivacyPolicyView',
  components: {
    
  },

  mounted() {
    
  }
}
</script>

<style scoped lang="scss">

</style>