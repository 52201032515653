<template>
	<main id="content" class="content_terms_use">
		<div class="alignment">

      <h1>Termos de Uso</h1>

    </div>
  </main>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'TermsOfUseView',
  components: {
    
  },

  mounted() {
    
  }
}
</script>

<style scoped lang="scss">

</style>